<template>
  <div class="page-home">
    <layout></layout>
    <live-create ref="liveCreateRef"></live-create>
  </div>
</template>

<script>
import Layout from '@/layout'
import LiveCreate from '@/views/yolo-cast/_live-create.vue'

export default {
  components: {
    Layout,
    LiveCreate,
  },
}
</script>
