<template>
  <div class="_head-view">
    <div class="_head-view__left">
      <!-- {{ matchMenu }} -->
    </div>
    <div class="_head-view__right">
      <div class="_head-view__right-user-info">
        <user-info :data="userInfo"></user-info>
        <i class="ui-icon-arrow-down-b"></i>
        <div class="_head-view__right-action-content">
          <p @click.stop="toAccount">
            <i class="_head-view__right-action-icon-account"></i>
            Account
          </p>
          <p @click.stop="toLogout">
            <i class="_head-view__right-action-icon-logout"></i>
            Log out
          </p>
        </div>
      </div>
      <!-- <y-divider style="height: 40px" direction="vertical"></y-divider>
      <div class="_head-view__language">
        <span class="_head-view__language-title">
          <i class="ui-icon-internation"></i>
          {{ current.label }}
          <i class="el-icon-arrow-down el-icon--right"></i>
        </span>
      </div> -->
      <!-- <y-dropdown class="_head-view__language" @command="command">
        <span class="_head-view__language-title">
          <i class="ui-icon-internation"></i>
          {{ current.label }}
          <i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <y-dropdown-menu slot="dropdown">
          <y-dropdown-item
            v-for="select in selects"
            :key="select.value"
            :command="select"
          >
            {{ select.label }}
          </y-dropdown-item>
        </y-dropdown-menu>
      </y-dropdown> -->
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { localStore } from '@/utils/store'
import UserInfo from '@components/user-info'
import { getLocale } from '@helper'

const locale = getLocale()

export default {
  components: {
    UserInfo
  },
  data() {
    return {
      options: [
        {
          label: 'English',
          value: 'en'
        },
        {
          label: '中文',
          value: 'zh-CN'
        }
      ],
      active: locale
    }
  },
  computed: {
    ...mapState({
      currentRoute(state) {
        return state.currentRoute
      },
      userInfo(state) {
        return state.userInfo
      }
    }),
    selects() {
      return this.options.filter((option) => option.value !== this.active)
    },
    current() {
      return this.options.find((option) => option.value === this.active)
    },
    matchMenu() {
      if (this.currentRoute.path === '/') {
        return ''
      }
      const matched = this.currentRoute.matched
      const activeMenu = matched[matched.length - 1]
      return activeMenu.meta.name
    }
  },
  methods: {
    command(current) {
      localStore.set('locale', current.value)
      location.reload()
    },
    toAccount() {
      if (this.$route.path === '/settings/account') {
        return
      }
      this.$router.push('/settings/account')
    },
    toLogout() {
      // 判断用户上传的文件是否上传完毕
      let uploadList = this.$store.state.uploadModal.uploadList;
      let uploading = false;
      if (uploadList && uploadList.length > 0) {
        for(let i = 0; i < uploadList.length; i++) {
          if (uploadList[i].uoloadtype === undefined) {
            uploading = true;
            break;
          }
        }
      }
      if (uploading) {
        this.$confirm("Uploading not ended. Are you sure you want to leave?", "Prompt", {
          confirmButtonText: "Done",
          cancelButtonText: "Cancel",
          type: "warning",
        }).then(async () => {
          this.$store.dispatch('logout');
        });
      } else {
        this.$store.dispatch('logout');
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/icon.scss';
._head-view {
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
._head-view__routes {
  font-weight: 500;
}
._head-view__right {
  display: flex;
  align-items: center;
}
._head-view__right-user-info {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  .ui-icon-arrow-down-b {
    margin-left: 18px;
  }
  &:hover {
    ._head-view__right-action-content {
      display: block;
    }
  }
}
._head-view__right-action-content {
  display: none;
  position: absolute;
  z-index: 9;
  top: 100%;
  right: 0;
  width: 150px;
  background: #ffffff;
  box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.06);
  border-radius: 2px;
  padding: 8px;
  p {
    line-height: 28px;
    display: flex;
    align-items: center;
    font-size: 12px;
    padding-left: 8px;
    cursor: pointer;
    color: #000;
    & + p {
      margin-top: 4px;
    }
    i {
      margin-right: 12px;
      display: inline-block;
      background-repeat: no-repeat;
      background-position: center;
      -webkit-background-size: cover;
      background-size: cover;
    }
    ._head-view__right-action-icon-account {
      @extend .ui-icon-account;
    }
    ._head-view__right-action-icon-logout {
      @extend .ui-icon-logout;
    }
    &:hover {
      background: #fffafb;
      color: $--color-primary;
      ._head-view__right-action-icon-account {
        @extend .ui-icon-account-hover;
      }
      ._head-view__right-action-icon-logout {
        @extend .ui-icon-logout-hover;
      }
    }
  }
}
._head-view__language {
  width: 86px;
  height: 64px;
  line-height: 64px;
  text-align: right;
  color: rgba(0, 0, 0, 0.4);
  font-size: 12px;
  .ui-icon-internation {
    vertical-align: -5px;
  }
}
._head-view__language-title {
  cursor: pointer;
}
</style>
