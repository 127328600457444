import { render, staticRenderFns } from "./_sub-side-view.vue?vue&type=template&id=0f75d1ca&scoped=true&"
import script from "./_sub-side-view.vue?vue&type=script&lang=js&"
export * from "./_sub-side-view.vue?vue&type=script&lang=js&"
import style0 from "./_sub-side-view.vue?vue&type=style&index=0&id=0f75d1ca&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f75d1ca",
  null
  
)

export default component.exports