<script>
export default {
  name: 'tree-menu',
  props: {
    data: {
      type: Array,
      default: () => []
    },
    active: String,
    subAcitve: String,
    collapse: Boolean
  },
  functional: true,
  render(_, { props }) {
    const { data, active, subAcitve, collapse } = props
    return data.map(({ meta, children }) => {
      let icon = meta?.icon
      if (active === meta?.realPath) {
        icon = meta.activeIcon || meta.icon
      }

      const title = meta?.name || meta.realPath

      if (collapse && children?.length) {
        return (
          <y-submenu
            key={meta.realPath}
            index={meta.realPath}
            class={`sub-menu
            ${active === meta?.realPath ? ' sub-menu--active' : ''}
            ${collapse ? ' sub-menu--collapse' : ''}`}
            popper-class="submenu-popper"
          >
            <template slot="title">
              {icon && <i class={icon}></i>}
              <span slot="title">{title}</span>
            </template>
            <tree-menu
              data={children}
              active={active}
              subAcitve={subAcitve}
            ></tree-menu>
          </y-submenu>
        )
      } else {
        return (
          <y-menu-item
            class={`menu-item
            ${
              active === meta?.realPath || subAcitve === meta?.realPath
                ? ' menu-item--active'
                : ''
            }
            ${collapse ? ' menu-item--collapse' : ''}`}
            key={meta.realPath}
            index={meta.realPath}
          >
            {icon && <i class={icon}></i>}
            <span slot="title">{title}</span>
          </y-menu-item>
        )
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.menu-item {
  height: 48px;
  line-height: 48px;
  padding: 0 8px !important;
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.6);
  background: transparent;
  & + .menu-item {
    margin-top: 4px;
  }
  i {
    margin-right: 10px;
  }
}
.sub-menu {
  ::v-deep {
    .el-submenu__title {
      height: 48px;
      line-height: 48px;
      margin-left: 8px;
      padding: 0 8px 0 0 !important;
      text-align: center;
      background: transparent;
    }
  }
}
.menu-item--active {
  color: $--color-primary;
  background: #fff;
  border-radius: 4px 0 0 4px;
  font-weight: 500;
}
.sub-menu--active {
  ::v-deep {
    .el-submenu__title {
      background: #fff;
      border-radius: 4px 0 0 4px;
    }
  }
}
.menu-item--collapse {
  ::v-deep {
    .el-tooltip {
      padding: 0 8px 0 0 !important;
      text-align: center;
    }
    i {
      margin-right: 0;
    }
  }
}
</style>
<style lang="scss">
.submenu-popper {
  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    transform: rotate(45deg);
    background: #fff;
    top: 10px;
    left: 4px;
  }
  &::before {
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
  }
  &::after {
    z-index: 101;
  }
  .menu-item {
    margin: 0;
    color: rgba(0, 0, 0, 0.6);
  }
  .menu-item--active {
    background: #fff0f4;
    border-radius: 4px;
  }
  ul {
    margin: 0 8px;
    padding: 16px 8px;
  }
}
</style>
