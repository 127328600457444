<template>
  <div class="com-user-info">
    <y-avatar
      :src="
        data.avatar ||
        'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png'
      "
    ></y-avatar>
    <div class="com-user-info__content">
      <p class="com-user-info__nickname">
        {{ data.nickname }}
      </p>
      <p class="com-user-info__email">
        {{ data.email }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default() {
        return {
          avatar: '',
          nickname: 'Loading',
          email: 'Loading'
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.com-user-info {
  display: flex;
  align-items: center;
}
.com-user-info__content {
  margin-left: 12px;
}
.com-user-info__nickname {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
}
.com-user-info__email {
  margin-top: 6px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
}
</style>
