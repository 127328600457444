<template>
  <div class="_side-drawer-view">
    <y-drawer
      direction="ltr"
      :visible.sync="drawer"
      :with-header="false"
      :modal-append-to-body="false"
      @closed="closed"
    >
      <side-view :screenxs="screenxs"></side-view>
    </y-drawer>
  </div>
</template>

<script>
import SideView from './_side-view'
export default {
  props: {
    screenxs: Boolean
  },
  components: {
    SideView
  },
  data() {
    return {
      drawer: false
    }
  },
  methods: {
    open() {
      this.drawer = true
    },
    close() {
      this.drawer = false
    },
    closed() {
      this.$emit('closed')
    }
  }
}
</script>

<style lang="scss" scoped>
._side-drawer-view {
  ::v-deep .el-drawer {
    width: 162px !important;
  }
}
</style>
