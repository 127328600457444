<template>
  <div
    :class="[
      '_side-view',
      {
        '_side-view--collapse': screenxs ? false : collapse
      }
    ]"
  >
    <div class="_side-view__inner">
      <div class="_side-view__logo">
        <router-link to="/">
          <img :src="logo" alt="YOLOLIV" />
        </router-link>
      </div>
      <!-- <div class="_side-view__action">
        <y-button type="primary" @click="create">
          New Event
          <i class="el-icon-arrow-down"></i>
        </y-button>
      </div> -->
      <y-menu
        router
        :default-active="active"
        :collapse="screenxs ? false : collapse"
        :collapse-transition="false"
      >
        <tree-menu
          :data="menuList"
          :active="active"
          :subAcitve="subAcitve"
          :collapse="collapse"
        ></tree-menu>
      </y-menu>
    </div>
    <!-- <div class="_side-view__storage">
      <storage></storage>
    </div> -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import TreeMenu from '@components/tree-menu'
// import Storage from '@components/storage'
import mainRoutes from '@router/main'
import logoFull from '@/assets/images/compang-logo-s.png'
import logoBrief from '@/assets/images/compang-logo-b.png'

export default {
  components: {
    TreeMenu
    // Storage
  },
  props: {
    screenxs: Boolean
  },
  data() {
    return {
      menuList: mainRoutes
    }
  },
  computed: {
    ...mapState({
      currentRoute(state) {
        return state.currentRoute
      },
      collapse(state) {
        return state.menuCollapse
      },
      logo() {
        if (this.screenxs) {
          return logoFull
        }
        return this.collapse ? logoBrief : logoFull
      }
    }),
    active() {
      if (this.currentRoute.path === '/') {
        return ''
      }
      const matched = this.currentRoute.matched

      /**
       * 在子菜单隐藏或者菜单隐藏的时候需要筛选菜单匹配
       */
      const menuMatched = matched.filter((menu) => {
        if (menu.parent?.meta?.hideSubMenu || menu.meta?.hideMenu) {
          return false
        }
        return true
      })
      return menuMatched[menuMatched.length - 1]?.meta?.realPath
    },
    subAcitve() {
      if (this.currentRoute.path === '/') {
        return ''
      }
      const matched = this.currentRoute.matched

      return matched[matched.length - 1]?.meta?.realPath
    }
  },
  methods: {
    create() {
      this.$store.commit('createRelUpdate', {
        type: 'event',
        show: true,
        action: '',
        done: false,
        payload: null
      })
    }
  }
}
</script>

<style lang="scss" scoped>
._side-view {
  height: 100vh;
}
._side-view__inner {
  height: calc(100% - 230px);
  overflow: auto;
  ::v-deep .el-menu {
    margin-top: 12px;
    background: transparent;
  }
}
._side-view__logo {
  a {
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  img {
    width: 114px;
  }
}
._side-view__action {
  text-align: center;
  .y-button {
    width: 146px;
  }
}
._side-view__storage {
  height: 230px;
}
::v-deep .el-menu {
  border-right: none;
}
._side-view--collapse {
  ._side-view__logo {
    img {
      width: 32px;
    }
  }
  ::v-deep .tree-menu__item {
    padding: 0;
    text-align: center;
    i {
      margin-right: 0;
    }
  }
}
</style>
