<template>
  <div class="_live-create">
    <y-dialog :visible.sync="visible" width="592px" @closed="closed">
      <div slot="title" class="_live-create__title">
        {{ model.activityId ? 'Edit a Live Event' : 'Create a Live Event' }}
      </div>
      <y-pro-form
        ref="formRef"
        label-width="100px"
        :model="model"
        :fields="fields"
        :rules="rules"
        :submitter="false"
      ></y-pro-form>
      <div slot="footer" class="_live-create__footer">
        <y-button size="small" @click="visible = false">Cancel</y-button>
        <y-button size="small" type="primary" @click="sure">
          {{ model.activityId ? 'Edit' : 'Create' }}
        </y-button>
      </div>
    </y-dialog>
  </div>
</template>

<script>
import moment from 'moment'

function getTime(date, time) {
  return (
    date.getFullYear() +
    '/' +
    (date.getMonth() + 1) +
    '/' +
    date.getDate() +
    ' ' +
    time.getHours() +
    ':' +
    time.getMinutes() +
    ':' +
    time.getSeconds()
  )
}

export default {
  data() {
    return {
      visible: false,
      model: {
        activityId: '',
        title: '',
        description: '',
        date: '',
        time: ''
      },
      fields: [
        {
          type: 'input',
          formItemProps: {
            label: 'Event Title',
            prop: 'title'
          },
          attributes: {
            size: 'small',
            showWordLimit: true,
            maxlength: 100,
            placeholder: 'Enter an event title'
          }
        },
        {
          type: 'textarea',
          formItemProps: {
            label: 'Description',
            prop: 'description'
          },
          attributes: {
            size: 'small',
            showWordLimit: true,
            rows: 4,
            maxlength: 300,
            placeholder: 'Add an event description'
          }
        },
        {
          formItemProps: {
            label: 'Schedule'
          },
          render(_, field, model) {
            return (
              <div style="display: flex; justify-content: space-between;">
                <el-date-picker
                  v-model={model['date']}
                  style="width: 206px"
                  type="date"
                  size="small"
                  placeholder="Select Date"
                  pickerOptions={{
                    disabledDate(time) {
                      const current = new Date()
                      const firstTime = new Date(
                        current.setDate(current.getDate() - 1)
                      ).getTime()
                      const lastTime = new Date(
                        current.setDate(current.getDate() + 7)
                      ).getTime()
                      return (
                        time.getTime() < firstTime || time.getTime() > lastTime
                      )
                    }
                  }}
                ></el-date-picker>
                <el-time-picker
                  v-model={model['time']}
                  style="width: 206px"
                  size="small"
                  placeholder="Select Time"
                  disabled={!model['date']}
                  pickerOptions={{
                    format: 'A hh:mm:ss',
                    selectableRange: (() => {
                      const dateTime = model['date']
                      const time = new Date()
                      const fdateTime = moment(dateTime).format('YYYY-MM-DD')
                      const ftime = moment(time).format('YYYY-MM-DD')
                      const diff = moment(fdateTime, 'YYYY-MM-DD').diff(
                        moment(ftime),
                        'days'
                      )
                      if (diff >= 1) {
                        return []
                      }
                      const h = time.getHours()
                      const m = time.getMinutes() + 1
                      return [
                        `${String(h).padStart(2, 0)}:${String(m).padStart(
                          2,
                          0
                        )}:00 - 23:59:59`
                      ]
                    })()
                  }}
                ></el-time-picker>
              </div>
            )
          }
        }
      ],
      rules: {
        title: [
          {
            required: true,
            message: 'The event title cannot be blank.'
          },
          {
            validator: (_, value, callback) => {
              if (!value.trim()) {
                callback(new Error('The event title cannot be blank.'))
                return
              }
              if (value.length > 100) {
                callback(
                  new Error('Please limit your title to 100 characters.')
                )
                return
              }
              callback()
            }
          }
        ]
      }
    }
  },
  watch: {
    '$store.state.createRel'(val) {
      if (val.type === 'event') {
        this.visible = val.show
        if (val.payload) {
          this.model = {
            activityId: val.payload.activityId,
            title: val.payload.title,
            description: val.payload.description,
            // 后端历史遗留问题: 给前端的时候时间是秒单位
            date: new Date(val.payload.startTime * 1000),
            time: new Date(val.payload.startTime * 1000)
          }
        }
      }
    }
  },
  methods: {
    sure() {
      const formInstance = this.$refs.formRef.getFormInstance()
      formInstance.validate((valid) => {
        if (!valid) {
          return
        }
        const data = {
          // 推流类型：1:直推模式 2:多路推流模式
          pushModel: 1,
          title: this.model.title,
          description: this.model.description,
          scheduleTime:
            this.model.date && this.model.time
              ? // 后端历史遗留问题: 给后端的时候是毫秒单位
                +new Date(getTime(this.model.date, this.model.time))
              : ''
        }
        if (this.model.activityId) {
          data.activityId = this.model.activityId
          this.update(data)
        } else {
          this.create(data)
        }
      })
    },
    async create(data) {
      const res = await this.$serve.activity.activityCreate({
        data
      })
      if (res === this.$serve.FAIL) {
        return
      }
      this.$message.success('Create Success')
      this.$store.commit('createRelUpdate', {
        type: 'event',
        show: false,
        action: 'create',
        done: true
      })
    },
    async update(data) {
      const res = await this.$serve.activity.activityEdit({
        data
      })
      if (res === this.$serve.FAIL) {
        return
      }
      this.$message.success('Update Success')
      this.$store.commit('createRelUpdate', {
        type: 'event',
        show: false,
        action: 'update',
        done: true
      })
    },
    closed() {
      const formInstance = this.$refs.formRef.getFormInstance()
      formInstance.resetFields()
      this.model = {
        activityId: '',
        title: '',
        description: '',
        date: '',
        time: ''
      }
      this.$store.commit('createRelUpdate', {
        type: 'event',
        show: false,
        action: '',
        done: false,
        payload: null
      })
    }
  }
}
</script>

<style lang="scss" scoped>
._live-create {
  ::v-deep .el-dialog__header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  }
  ::v-deep .el-dialog__body {
    padding-left: 32px;
    padding-right: 32px;
  }
  ::v-deep .el-dialog__footer {
    border-top: 1px solid rgba(0, 0, 0, 0.06);
  }
}
._live-create__title {
  text-align: center;
  font-weight: 600;
}
</style>
