import { localStore } from '@/utils/store'
// import messages from '@locales'
import { language } from '@config/env'

const languages = Object.keys({})

export const getLocale = () => {
  let locale = localStore.get('locale')
  if (!languages.includes(locale)) {
    locale = language
  }
  return locale
}
