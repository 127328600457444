<template>
  <container-query :query="query" v-model="screenData">
    <div :class="['layout', screenData]">
      <y-container>
        <!-- 侧边栏 -->
        <y-aside :width="collapse ? '64px' : '162px'" class="layout__side">
          <side-view></side-view>
        </y-aside>
        <!-- /侧边栏 -->

        <!-- 二级侧边栏 -->
        <y-aside
          v-if="hasSubSide"
          v-show="!collapse"
          width="128px"
          class="layout__sub-side c-line-right"
        >
          <sub-side-view></sub-side-view>
        </y-aside>
        <!-- 二级侧边栏 -->

        <y-main class="layout__main">
          <y-container>
            <!-- 顶部栏 -->
            <y-header style="height: 64px" class="layout__head c-line-bottom">
              <head-view></head-view>
              <span class="layout__head__collapsed" @click="collapseToggle">
                <i
                  :class="
                    collapse ? 'ui-icon-toggle-open' : 'ui-icon-toggle-close'
                  "
                ></i>
              </span>
            </y-header>
            <!-- /顶部栏 -->

            <y-container>
              <y-main class="layout__body">
                <el-alert
                  style="margin: 20px 16px 0 16px; width: auto"
                  type="error"
                >
                  <p style="font-size: 24px">
                    <a
                      style="color: #ff0043"
                      href="https://dashboard.yololiv.com/"
                      target="_blank"
                    >
                      Click here to start live event for free on YoloCast.
                    </a>
                  </p>
                </el-alert>
                <router-view></router-view>
              </y-main>
            </y-container>

            <!-- 底部栏 -->
            <!-- <y-footer class="layout__foot">
              <foot-view></foot-view>
            </y-footer> -->
            <!-- /底部栏 -->
          </y-container>
        </y-main>
      </y-container>
      <side-drawer-view
        class="layout__side-drawer-view"
        ref="sideDrawerRef"
        :screenxs="screenxs"
        @closed="sideDrawerClosed"
      ></side-drawer-view>
    </div>
  </container-query>
</template>

<script>
import { ContainerQuery } from 'vue-container-query'
import { mapState } from 'vuex'
import HeadView from './_head-view'
import SideView from './_side-view'
import SubSideView from './_sub-side-view'
import SideDrawerView from './_side-drawer-view.vue'
import FootView from './_foot-view'

const query = {
  'screen-xs': {
    maxWidth: 575
  },
  'screen-sm': {
    minWidth: 576,
    maxWidth: 767
  },
  'screen-md': {
    minWidth: 768,
    maxWidth: 991
  },
  'screen-lg': {
    minWidth: 992,
    maxWidth: 1199
  },
  'screen-xl': {
    minWidth: 1200,
    maxWidth: 1599
  },
  'screen-xxl': {
    minWidth: 1600
  }
}

export default {
  components: {
    ContainerQuery,
    HeadView,
    SideView,
    SideDrawerView,
    SubSideView,
    // eslint-disable-next-line vue/no-unused-components
    FootView
  },
  data() {
    return {
      query,
      screenData: {}
    }
  },
  computed: {
    ...mapState({
      collapse(state) {
        return state.menuCollapse
      },
      currentRoute(state) {
        return state.currentRoute
      }
    }),
    drawerOpen() {
      return this.collapse
    },
    screenxs() {
      return this.screenData['screen-xs']
    },
    hasSubSide() {
      // todo 暂时隐藏
      if (this.currentRoute.fullPath === '/yolo-cast/events-list') {
        return false
      }
      return this.currentRoute.matched.length > 2
    }
  },
  watch: {
    drawerOpen(val) {
      if (val) {
        this.$refs.sideDrawerRef.open()
      } else {
        this.$refs.sideDrawerRef.close()
      }
    },
    screenData(data) {
      this.$store.commit('screenDataUpdate', data)
    }
  },
  methods: {
    sideDrawerClosed() {
      this.$store.commit('menuCollapseUpdate', false)
    },
    collapseToggle() {
      this.$store.commit('menuCollapseUpdate', !this.collapse)
    }
  }
}
</script>

<style lang="scss" scoped>
.layout__side {
  height: 100vh;
}

.layout__side {
  transition: all 0.01s;
  background: #fff0f4;
}

.layout__main {
  padding: 0;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 6px;
    background: transparent;
  }
  &::-webkit-scrollbar-track {
    border-radius: 0;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(102, 102, 102, 1);
    transition: all 0.2s;
    border-radius: 2px;
  }
}

.layout__body {
  min-height: calc(100vh - 64px);
  padding: 0;
}

.el-drawer__wrapper {
  display: none;
}

.layout__side-drawer-view {
  display: none;
}

.screen-xs {
  .layout__side {
    display: none;
  }

  .layout__side-drawer-view {
    display: block;
  }
}
.layout__head__collapsed {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 34px;
  height: 24px;
  background: rgba(0, 0, 0, 0.04);
  border-radius: 0px 13px 13px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
</style>
