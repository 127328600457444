<template>
  <div class="_sub-side-view">
    <div class="_sub-side-view__head c-line-bottom">
      <p>{{ topRoute.meta.name }}</p>
    </div>
    <y-menu router :default-active="active">
      <y-menu-item
        v-for="menu in menuList"
        :key="menu.meta.realPath"
        :index="menu.meta.realPath"
      >
        <div class="_sub-side-view__menu-item">
          {{ menu.meta.name || menu.meta.realPath }}
        </div>
      </y-menu-item>
    </y-menu>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState({
      currentRoute(state) {
        return state.currentRoute
      }
    }),
    topRoute() {
      const matched = this.currentRoute.matched
      return matched[matched.length - 2] || { meta: { name: '' } }
    },
    menuList() {
      return this.topRoute.meta?.children || []
    },
    active() {
      const matched = this.currentRoute.matched
      return matched[matched.length - 1]?.meta?.realPath
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .el-menu {
    border-right: none;
  }
  .el-menu-item {
    margin-top: 8px;
    padding: 0 8px !important;
    line-height: 32px;
    height: 32px;
  }
  .el-menu-item:hover,
  .el-menu-item:focus {
    background-color: transparent;
  }
}

._sub-side-view {
  height: 100vh;
  overflow-y: auto;
}

._sub-side-view__head {
  text-align: center;
  line-height: 64px;
  font-weight: 500;
}
._sub-side-view__menu-item {
  padding: 0 12px;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.6);
}
.is-active ._sub-side-view__menu-item {
  background: #fff0f4;
  color: $--color-primary;
}
</style>
